<template>
  <div class="carousel-wrapper">
    <b-carousel
      class="banner-carousel"
      v-model="slide"
      controls
      img-width="1024"
      img-height="600"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd">

      <b-carousel-slide class="slide-1" img-blank>
        <b-row
          class="justify-content-center content"
          align-v="center">
          <b-col class="text-center" cols="12" xl="5">
            <img
              class="img-fluid pj-panda-crown"
              :src="images.pjPanda"
              alt="PJ Panda with Crown"
            />
            <img
              class="img-fluid pj-panda"
              :src="images.pjPandaFull"
              alt="PJ Panda"
            />
          </b-col>
          <b-col cols="12" xl="7">
            <div class="text-center">
              <h1>
                FOR THE CHILDREN
                <br>
                FOR THE CULTURE
              </h1>
              <br>
              <b-row
                class="justify-content-center"
                align-v="center"
                no-gutters>
                <b-col cols="auto">
                  <div class="text-right">
                    <img
                      class="img-fluid apple-store"
                      :src="home.appleStore"
                      alt="Apple Store Logo"
                      title="Download Healthy Hip Hop for iOS"
                      @click="redirect(appStoreLink)"
                    />
                    <img
                      class="img-fluid play-store"
                      :src="home.playStore"
                      alt="Play Store Logo"
                      title="Download Healthy Hip Hop for Android"
                      @click="redirect(playStoreLink)"
                    />
                  </div>
                </b-col>
                <b-col cols="auto">
                  <p class="download-text">DOWNLOAD THE APP</p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-carousel-slide>

      <b-carousel-slide class="slide-2" img-blank>
        <b-row
          class="justify-content-center content"
          align-v="center"
          no-gutters>
          <b-col class="text-center" cols="12" xl="5">
            <h1>DOWNLOAD THE HEALTHY HIP HOP MOBILE APP</h1>
            <br>
            <b-row
              class="justify-content-center"
              align-v="center"
              no-gutters>
              <b-col cols="auto">
                <div class="text-right">
                  <img
                    class="img-fluid apple-store"
                    :src="home.appleStore"
                    alt="Apple Store Logo"
                    title="Download Healthy Hip Hop for iOS"
                    @click="redirect(appStoreLink)"
                  />
                  <img
                    class="img-fluid play-store"
                    :src="home.playStore"
                    alt="Play Store Logo"
                    title="Download Healthy Hip Hop for Android"
                    @click="redirect(playStoreLink)"
                  />
                </div>
              </b-col>
              <b-col cols="auto">
                <p class="download-text">DOWNLOAD THE APP</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="text-center" cols="12" xl="7">
            <img
              class="img-fluid pj-app"
              :src="images.pjApp"
              alt="H3 Mobile App"
            />
          </b-col>
        </b-row>
      </b-carousel-slide>

      <b-carousel-slide class="slide-3" img-blank>
        <b-row
          class="justify-content-center content"
          align-v="center">
          <b-col cols="12" xl="5">
            <img
              class="img-fluid leo"
              :src="images.leo"
              alt="PJ Panda"
            />
          </b-col>
          <b-col cols="12" xl="7">
            <h1>BORN TO BE WILD</h1>
            <h3>AVAILABLE NOW</h3>
            <br>
            <b-row
              class="justify-content-center"
              align-v="center"
              no-gutters>
              <b-col cols="auto">
                <div class="text-right">
                  <img
                    class="img-fluid apple-store"
                    :src="home.appleStore"
                    alt="Apple Store Logo"
                    title="Download Healthy Hip Hop for iOS"
                    @click="redirect(appStoreLink)"
                  />
                  <img
                    class="img-fluid play-store"
                    :src="home.playStore"
                    alt="Play Store Logo"
                    title="Download Healthy Hip Hop for Android"
                    @click="redirect(playStoreLink)"
                  />
                </div>
              </b-col>
              <b-col cols="auto">
                <p class="download-text">DOWNLOAD THE APP</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
  import ImageMixin from '@/mixins/ImageMixin';

  export default {
    mixins : [
      ImageMixin,
    ],
    data() {
      return {
        appStoreLink : 'https://apps.apple.com/us/app/healthy-hip-hop/' +
          'id1456576616',
        playStoreLink : 'https://play.google.com/store/apps/details?' +
          'id=com.healthyhiphop',
        slide   : 0,
        sliding : null,
        images  : {
          bg1 : require('../../assets/images/home/Mission-Background.png'),
          bg2 : require(
            '../../assets/images/home/section-1-part-2-landing-page-bg.jpg'),
          bg3 : require(
            '../../assets/images/home/section-1-part-3-landing-page-bg.jpg'),
          pjPanda : require(
            '../../assets/images/home/section-1-landing-page-pj.png'),
          pjPandaFull : require(
            '../../assets/images/home/section-1-landing-page-pj-full.png'),
          pjApp : require(
            '../../assets/images/home/section-1-part-2-pj-panda-app.png'),
          leo : require(
            '../../assets/images/home/section-1-part3-born-to-be-wild.png'),
        },
      }
    },
    methods : {

      onSlideStart(slide) {
        this.sliding = true
      },

      onSlideEnd(slide) {
        this.sliding = false
      },

      /**
       * Redirect page to download H3 App
       * @param link
       */
      redirect(link) {
        if (link == this.appStoreLink) {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'appstore-ios',
          });
        } else {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'playstore-android',
          });
        }
        window.open(link, '_target');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  h1 {
    font-size: 3.25em !important;
    -webkit-text-stroke: 2.5px #0a1d41 !important;
  }

  .download-text {
    margin-bottom: 0;
    padding-left: 1rem;
    color: #fff;
    font-family: 'WickedMouse' !important;
    font-size: 1.25em !important;
    -webkit-text-stroke: 2px #0a1d41 !important;
  }

  .apple-store,
  .play-store {
    padding: 0 0.25rem;
    width: 3em;
    &:hover {
      cursor: pointer;
    }
  }

  .pj-panda-crown {
    width: 95%;
  }
  .pj-panda {
    display: none;
  }

  .pj-app {
    margin-top: 2rem;
    margin-bottom: 8rem;
  }

  .slide-1 {
    background-image: $section1-bg;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slide-2 {
    background-image: $slide-2;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      font-size: 3.25rem !important;
      -webkit-text-stroke: 2px #1272eb !important;
    }
  }

  .slide-3 {
    background-image: $slide-3;
    background-repeat: no-repeat;
    background-size: cover;

    h1, h3 {
      -webkit-text-stroke: 2px #eba50f !important;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    h1 {
      font-size: 1.7em !important;
      -webkit-text-stroke: 1px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.3rem;
      font-size: 1em !important;
      -webkit-text-stroke: 1px #0a1d41 !important;
    }
    .pj-panda {
      display: inline;
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 45%;
    }
    .pj-panda-crown {
      display: none;
    }
    .slide-2 {
      h1 {
        font-size: 1.7rem !important;
        -webkit-text-stroke: 1px #1272eb !important;
      }
    }
    .slide-3 {
      h1, h3 {
        -webkit-text-stroke: 1px #eba50f !important;
      }
    }
    .carousel-item {
      height: 36rem;
    }
    .pj-app {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .leo {
      margin-bottom: 2rem;
      width: 80%;
    }
  }

  /* iPhone 6/7/8 Plus */
  @media only screen
    and (device-width : 414px)
    and (device-height : 736px)
    and (-webkit-device-pixel-ratio : 3) {
      .carousel-item {
        height: 43rem;
      }
      .pj-app {
        margin-bottom: 5rem;
      }
    }

  /* iPhone X and Xs Max */
  @media only screen
    and (min-device-width: 375px)
    and (min-device-height: 812px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
      .carousel-item {
        height: 40rem;
      }
      .pj-app {
        margin-bottom: 3rem;
      }
    }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    h1 {
      font-size: 2.5em !important;
      -webkit-text-stroke: 1.75px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.3rem;
      font-size: 1.15em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .pj-panda {
      display: inline;
      margin-top: 1.5rem; //1rem;
      margin-bottom: 1rem;
      width: 45%;
    }
    .pj-panda-crown {
      display: none;
    }

    .slide-2 {
      h1 {
        font-size: 2.5rem !important;
        -webkit-text-stroke: 1px #1272eb !important;
      }
    }

    .carousel-item {
      height: 47rem;
    }
    .pj-app {
      margin-bottom: 3rem;
    }
    .leo {
      width: 80%;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    h1 {
      font-size: 2.75em !important;
      -webkit-text-stroke: 1.75px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.3rem;
      font-size: 1.15em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .pj-panda {
      width: 45%;
    }
    .pj-panda-crown {
      display: none;
    }
    .carousel-item {
      height: 55rem;//50rem;
    }
    
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    h1 {
      font-size: 3em !important;
      -webkit-text-stroke: 2px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.5rem;
      font-size: 1.5em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .pj-panda {
      width: 40%;
    }

    .carousel-item {
      height: 55rem;
    }
    .leo {
      width: 70%;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .download-text {
      padding-left: 0.5rem;
      font-size: 1.3em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .pj-panda {
      display: none;
    }
    .pj-panda-crown {
      display: inline;
      width: 75%; //65%;
    }

    .carousel-item {
      height: 39rem;
    }
    .slide-2 {
      .content {
        padding-bottom: 3rem;
      }
    }
    .slide-3 {
      .content {
        padding-bottom: 6rem;
      }
      .leo {
        width: 85%;
      }
    }
  }

  @media only screen and (min-width: $special) {
    .download-text {
      padding-left: 0.5rem;
      font-size: 1.3em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .pj-panda-crown {
      width: 73%;
    }
    .carousel-item {
      height: 40rem;
    }
    .slide-2 {
      .content {
        padding-bottom: 3rem;
      }
    }
    .slide-3 {
      .content {
        padding-bottom: 6rem;
      }
      .leo {
        width: 90%;
      }
    }
  }

  @media only screen and (min-width: $xl2-min) {
    h1 {
      font-size: 3.2em !important;
      -webkit-text-stroke: 2.5px #0a1d41 !important;
    }
    .pj-panda-crown {
      width: 28rem;
    }
    .carousel-item {
      height: 58rem;
    }

    .slide-2 {
      .content {
        padding-bottom: 5rem;
      }
      h1 {
        font-size: 2.5em !important;
        -webkit-text-stroke: 2px #1272eb !important;
      }
      .pj-app {
        margin-bottom: 6rem;
      }
    }

    .slide-3 {
      .content {
        padding-bottom: 9rem;
      }
      h1, h3 {
        -webkit-text-stroke: 2px #eba50f !important;
      }
      .leo {
        width: 90%;
      }
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .carousel-item {
      height: 75rem;
    }
    .slide-1 {
      .content {
        margin: 0 auto;
        width: 100rem;
      }
      .pj-panda-crown {
        width: 35rem;
      }
    }
    .slide-2 {
      .content {
        margin: 0 auto;
        margin-bottom: 3rem;
        width: 100rem;
      }
      h1 {
        font-size: 3em !important;
        -webkit-text-stroke: 2px #1272eb !important;
      }
    }
    .slide-3 {
      .content {
        margin: 0 auto;
        margin-bottom: 8rem;
        width: 100rem;
      }
      .leo {
        width: 40rem;
      }
    }
  }
</style>